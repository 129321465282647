<template>
  <div class="row mx-0">
    <div class="col-12 p-0" v-if="section18ATransactions && section18ATransactions.length > 0">
      <div class="row m-0 justify-content-center">
        <div class="col-auto my-3">
          <DonationsFilter @updateDate="updateDate" @updateDonationType="updateDonationType" @updatePrice="updatePrice" @updateDonor="updateDonor" :selectedItems="selectedItems" @printSelected="printSelected" @printRange="printRange" @downloadSelected="downloadSelected" @downloadRange="downloadRange" @downloadAll="downloadAll" />
        </div>
      </div>
      <!-- TABLE HEADING -->
      <div class="row m-0">
        <div class="col-12 px-0 px-md-3">
          <!--DESKTOP-->
          <div class="row font13 green-text-dark d-none d-md-flex">
            <div class="col-md-3 bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3 rounded-left">
                DONOR
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3">
                TRANSACTION REF
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3">
                DATE
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3">
                AMOUNT
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3">
                PAYMENT TYPE
              </div>
            </div>
            <div class="col-md-1 bold text-md-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3 rounded-right">
                INFO
              </div>
            </div>
          </div>
          <!--END DESKTOP-->
          <!--MOBILE-->
          <div class="row font13 green-text-dark d-md-none bold">
            <div class="col-3 text-center py-3 px-1">
              Receipt No.
            </div>
            <div class="col-3 text-center py-3 px-1">
              Date
            </div>
            <div class="col-3 text-center py-3 px-1">
              Amount
            </div>
            <div class="col-3 text-center py-3 px-1">
              Info
            </div>
          </div>
          <!--END MOBILE-->
        </div>
      </div>
      <!-- END TABLE HEADING -->
      <div class="row m-0" v-if="filteredDonations.length < 1">
        <div class="col-12 p-3">
          There are no donations for the current filter, please try a different filter.
        </div>
      </div>
      <PastDonationItem v-for="(donation, key) in filteredDonations.slice(start, finish)" :key="key" :item="donation" :thekey="key" @showDetails="showDetails(key)" @downloadReceipt="downloadReceipt(donation.transactionID)" @printReceipt="printReceipt(donation.transactionID)" @addSelected="addSelected(donation.transactionID)" @removeSelected="removeSelected(donation.transactionID)" />
      <transition name="slideup">
      <DonationPopup v-if="currentItem" :item="currentItem" :theKey="currentKey" :totalItems="filteredDonations.length" @close="closeDetails" @goForward="goForward(currentKey)" @goBack="goBack(currentKey)" />
      </transition>
      <!-- PAGINATION -->
      <div class="row mx-0 mt-5" v-if="totalPages > 1">
        <div class="col-12 text-center">
          <div class="d-inline p-2 cursor" v-for="index in totalPages" :key="index" @click="goToPage(index)" :class="{bold: currentPage === index - 1}">
            {{ index }}
          </div>
        </div>
      </div>
      <!-- END PAGINATION -->
    </div>
    <div class="col-12 p-0" v-else>
      <div class="row mx-0 justify-content-center align-items-center">
        <div class="col-12 text-center mb-3">
          You don't have any past donations, try donating now.
        </div>
        <div class="col-12 text-center">
          <Button color="red" btnText="Donate Now" icon="arrow" @buttonClicked="goTo('appeals/1')">
            <IconArrowForward color="white" size="size20" />
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    PastDonationItem: defineAsyncComponent(() => import('./PastDonationItem18A.vue')),
    DonationsFilter: defineAsyncComponent(() => import('./DonationsFilter18A.vue')),
    DonationPopup: defineAsyncComponent(() => import('@/views/DonationPopup.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue'))
  },
  name: 'PastDonations18A',
  data () {
    return {
      projectType: null,
      startDate: null,
      endDate: null,
      priceRange: null,
      donationType: null,
      donor: null,
      itemsPerPage: 10,
      currentPage: 0,
      currentItem: null,
      currentKey: null,
      selectedItems: []
    }
  },
  mounted () {
    this.fetch18ATransactions()
  },
  computed: {
    ...mapGetters(['user', 'section18ATransactions']),
    totalPages () {
      return Math.ceil(this.filteredDonations.length / this.itemsPerPage)
    },
    start () {
      return this.currentPage * this.itemsPerPage
    },
    finish () {
      return this.start + this.itemsPerPage
    },
    filteredDonations () {
      return this.section18ATransactions.filter(transaction => {
        if (this.donationType) {
          let ret = false
          transaction.donationList.forEach(item => {
            if (this.donationType.includes(item.donationType.donationTypeID)) {
              ret = true
            }
          })
          if (ret === false) {
            return false
          }
        }
        if (this.donor) {
          let ret = false
          this.donor.forEach((donor) => {
            if (donor.substring(0, 3) === 'org') {
              if (transaction.organisationID && donor === 'org' + transaction.organisationID) {
                ret = true
              }
            } else if (donor.substring(0, 3) === 'don') {
              if (!transaction.organisationID && donor === 'don' + transaction.userID) {
                ret = true
              }
            }
          })
          if (ret === false) {
            return false
          }
        }
        if (this.startDate && this.endDate && (transaction.transactionDate <= this.startDate || transaction.transactionDate >= this.endDate)) {
          return false
        }
        if (this.priceRange) {
          let ret = false
          this.priceRange.forEach((price) => {
            var res = price.split('-')
            for (var i = 0; i < res.length; i++) {
              res[i] = +res[i]
            }
            if (res[1] !== 1001 && transaction.estimatedValue && transaction.estimatedValue >= res[0] && transaction.estimatedValue < res[1]) {
              ret = true
            } else if (res[1] === 1001 && transaction.estimatedValue && transaction.estimatedValue >= res[0]) {
              ret = true
            }
          })
          if (ret === false) {
            return false
          }
        }
        return true
      })
    }
  },
  methods: {
    ...mapActions(['printTransactionReceipt', 'downloadSection18', 'downloadMultipleSection18', 'downloadDatesSection18', 'downloadSection18Summary', 'fetch18ATransactions']),
    goToPage (val) {
      this.currentPage = val - 1
      this.$emit('goToDonations')
    },
    updateDate (val) {
      if (val) {
        const handler = {
          get (target, property) {
            return target[property]
          }
        }
        const proxyVal = new Proxy(val, handler)
        this.startDate = proxyVal.startDate
        this.endDate = proxyVal.endDate
      } else {
        this.startDate = null
        this.endDate = null
      }
      this.currentPage = 0
    },
    updatePrice (val) {
      if (val === null) {
        this.priceRange = null
      } else {
        this.priceRange = val
      }
      this.currentPage = 0
    },
    updateDonor (val) {
      if (val === null) {
        this.donor = null
      } else {
        this.donor = val
      }
      this.currentPage = 0
    },
    updateDonationType (val) {
      if (val === null) {
        this.donationType = null
      } else {
        this.donationType = val
      }
      this.currentPage = 0
    },
    goTo (val) {
      window.scrollTo(0, 0)
      this.$router.push('/' + val)
    },
    showDetails (val) {
      this.currentKey = this.start + val
      this.currentItem = this.filteredDonations[this.currentKey]
    },
    async printReceipt (val) {
      await this.printTransactionReceipt(val)
    },
    printSelected () {
      this.selectedItems.forEach(item => {
        this.printReceipt(item)
      })
    },
    printRange (val) {
      this.section18ATransactions.forEach(item => {
        if (item.transactionDate >= val.startDate && item.transactionDate <= val.endDate) {
          this.printReceipt(item.transactionID)
        }
      })
    },
    async downloadReceipt (val) {
      await this.downloadSection18(val)
    },
    async downloadSelected () {
      const details = {
        transactionIDs: [],
        userID: this.user.userID
      }
      this.selectedItems.forEach(item => {
        details.transactionIDs.push(item)
      })
      if (this.selectedItems.length > 1) {
        await this.downloadMultipleSection18(details)
      } else {
        await this.downloadReceipt(this.selectedItems[0])
      }
    },
    async downloadRange (val) {
      const details = {
        startDate: DateTime.fromMillis(val.startDate).toFormat('yyyy-MM-dd'),
        endDate: DateTime.fromMillis(val.endDate).toFormat('yyyy-MM-dd'),
        userID: this.user.userID
      }
      await this.downloadDatesSection18(details)
    },
    async downloadAll () {
      await this.downloadSection18Summary()
    },
    goForward (val) {
      this.currentItem = this.filteredDonations[val + 1]
      this.currentKey = val + 1
    },
    goBack (val) {
      this.currentItem = this.filteredDonations[val - 1]
      this.currentKey = val - 1
    },
    closeDetails () {
      this.currentItem = null
      this.currentKey = null
    },
    addSelected (val) {
      this.selectedItems.push(val)
    },
    removeSelected (val) {
      var idx = this.selectedItems.indexOf(val)
      if (idx !== -1) this.selectedItems.splice(idx, 1)
    }
  }
}
</script>

<style scoped>
.rounded-left {
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
}
.rounded-right {
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}
@media (min-width: 992px) {
  .rounded-left {
    border-bottom-left-radius: 100px;
    border-top-left-radius: 100px;
  }
  .rounded-right {
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
  }
}
.slideup-enter-active,
.slideup-leave-active {
  transition: all 0.5s ease;
}

.slideup-enter-from,
.slideup-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
</style>
